import React from "react"
import "./style.css"
import { Member } from "../../../types/graphql-types"
import "./style.css"
import "../utils/utilStyle.css"

type Props = {
  item: Member
  isEn: boolean
}
const MemberItem: React.FC<Props> = props => (
  <div className={"member-item-wrapper"}>
    <div>
      <img src={props.item.img?.url || ""} className={"member-img"} />
    </div>
    <div className={"text-field-wrapper"}>
      <div
        className={
          props.isEn
            ? "official-position-wrapper-for-en"
            : "official-position-wrapper"
        }
      >
        <p className={"delete-p-margin"}>{props.item.post}</p>
      </div>
      <div className={props.isEn ? "name1-wrapper-for-en" : "name1-wrapper"}>
        <p className={"delete-p-margin"}>{props.item.name}</p>
      </div>
      <div className={props.isEn ? "name2-wrapper-for-en" : "name2-wrapper"}>
        <p className={"delete-p-margin"}>{props.item.roman_letter}</p>
      </div>
      <div className={"detail-wrapper"}>
        <p className={"delete-p-margin"}>
          <div
            dangerouslySetInnerHTML={{
              __html: `${props.item.body}`
            }}
            className={props.isEn ? "en" : "ja"}
          />
        </p>
      </div>
    </div>
  </div>
)

export default MemberItem
