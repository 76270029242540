import React from "react"
import ".//style.css"
import {
  Maybe,
  Member,
  MemberWithOutImg,
  Scalars
} from "../../../types/graphql-types"
import MemberItem from "./MemberItem"
import MemberWithoutImgItem from "./MemberWithoutImg"

type Props = {
  members?: Maybe<Array<Maybe<Member>>>
  members_without_img?: Maybe<Array<Maybe<MemberWithOutImg>>>
  isEn: boolean
  title?: Maybe<Scalars["String"]>
}
const MemberSection: React.FC<Props> = props => {
  return (
    <div>
      <div className={"members-section-title-wrapper"}>
        <p className={"members-section-title"}>{props.title}</p>
      </div>
      <div className={"members-wrapper"}>
        {props.members &&
          props.members.length > 0 &&
          props.members.map(
            item =>
              item && (
                <MemberItem
                  key={`member_item_${item.roman_letter}`}
                  item={item}
                  isEn={props.isEn}
                />
              )
          )}
      </div>
      <div className={"members-wrapper"}>
        {props.members_without_img &&
          props.members_without_img.length > 0 &&
          props.members_without_img.map(
            item =>
              item && (
                <MemberWithoutImgItem
                  key={`member_with_out_img_${item.roman_letter}`}
                  item={item}
                  isEn={props.isEn}
                />
              )
          )}
      </div>
    </div>
  )
}

export default MemberSection
