import React from "react"
import { graphql, PageProps } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Query } from "../../../types/graphql-types"
import PageTitle from "../../components/utils/PageTitle"
import "../../components/Team/style.css"
import MemberSection from "../../components/Team/MemberSection"
import { en_description } from "../../components/const"

const Team: React.FC<PageProps<Query>> = ({ data, location }) => {
  const teamData = data.microcmsTeamEn
  return (
    <Layout selectPageName="Team" location={location}>
      <SEO
        title="Team"
        description={en_description}
        lang={"en"}
        twitterTitle={"D Capital | Team"}
      />
      {teamData?.title && (
        <PageTitle title={teamData.title} key={teamData.id} />
      )}
      <div className={"members-margin-wrapper"}>
        {teamData?.section &&
          teamData.section.length > 0 &&
          teamData.section.map(
            item =>
              item && (
                <MemberSection
                  key={item.section_title}
                  title={item.section_title}
                  isEn={true}
                  members={item.member}
                  members_without_img={item.member_without_img}
                />
              )
          )}
      </div>
    </Layout>
  )
}

export default Team

export const TeamIndexQueryForEn = graphql`
  query TeamIndexQueryForEn {
    microcmsTeamEn {
      title
      section {
        member {
          body
          img {
            url
          }
          name
          post
          roman_letter
        }
        member_without_img {
          body
          name
          post
          roman_letter
        }
        section_title
      }
    }
  }
`
